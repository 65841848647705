import { LoadingPage, NetworkErrorPage } from '@lib/common';
import 'antd/dist/antd.css';
import { NextPage } from 'next';
import { appWithTranslation, i18n } from 'next-i18next';
import App, { AppContext, AppProps } from 'next/app';
import { withRouter } from 'next/router';
import React, { ReactNode } from 'react';
import { runAsyncEpics, runAuthAsyncEpics } from '../redux/root.epic';
import { wrapper } from '../redux/store';
import Head from 'next/head';
import Script from 'next/script';
import getConfig from 'next/config';
import { SessionProvider } from 'next-auth/react';
import { loadAppRouters } from '../routes';
import { routeManager } from '@lib/route-manager';

interface State {
  isOnline: boolean;
  isValidToken: boolean;
}

type Page<P = {}> = NextPage<P> & {
  getLayout?: (page: ReactNode) => ReactNode;
};

type Props = AppProps & {
  Component: Page;
};
class MyApp extends App<Props, {}, State> {
  public getLayout;

  constructor(props: any) {
    super(props);
    this.state = {
      isOnline: true,
      isValidToken: false,
    };
    this.getLayout = this.props.Component.getLayout ?? ((page: ReactNode) => page);
  }

  public static getInitialProps = wrapper.getInitialAppProps(() => async (context) => {
    return {
      pageProps: {
        ...(await App.getInitialProps(context)).pageProps,
      },
    };
  });

  async componentDidMount() {
    loadAppRouters();

    const isRunningClientSide = process.browser;
    if (isRunningClientSide) {
      if (localStorage.getItem('access_token')) {
        await runAsyncEpics();
      } else {
        await runAuthAsyncEpics();
      }
    }
    this.setState({ isOnline: navigator.onLine });
  }

  render() {
    const { Component, pageProps } = this.props;
    const { publicRuntimeConfig } = getConfig();

    const renderPageDetail = () =>
      this.state.isOnline ? (
        <>
          <Head>
            <link rel="shortcut icon" href="/images/Favicon.svg" />
            <title>{i18n?.t('appTitle')}</title>
          </Head>
          <Script
            src="https://www.googletagmanager.com/gtag/js?id=G-4F6V6CG61Y"
            strategy="afterInteractive"
          />
          <Script
            id="ms_clarity"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
               (function(c,l,a,r,i,t,y){
                  c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                  t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                  y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
              })(window, document, "clarity", "script", "${publicRuntimeConfig.env.CLARITY_PROJECT_ID}");`,
            }}
          />
          {this.getLayout(
            <SessionProvider session={pageProps.session}>
              <Component {...pageProps} />
            </SessionProvider>,
          )}
        </>
      ) : (
        <NetworkErrorPage />
      );

    if (this.state.isValidToken) {
      const clientId = this.props.router?.query.clientId ?? 'teacher';
    }

    return routeManager.isReady ? renderPageDetail() : <LoadingPage />;
  }
}

const MyAppTranslationWrapper = appWithTranslation(MyApp);
export default withRouter(wrapper.withRedux(MyAppTranslationWrapper));
